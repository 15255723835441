.datatable-rowgroup-demo .p-rowgroup-footer td {
    font-weight: 700;
}

.datatable-rowgroup-demo .p-rowgroup-header span {
    font-weight: 700;
}

.datatable-rowgroup-demo .p-rowgroup-header .p-row-toggler {
    vertical-align: middle;
    margin-right: .25rem;
}

.datatable-rowgroup-demo .p-datatable-rowgroup-demo .tbody, td, tfoot, th, thead, tr {
    --width: 150px;
} 
