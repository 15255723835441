/* .p-grid .print {
    height: "330px";
}

.p-col-6 .print {
    width: 48%;
    height: fit-content;
} */

.print-font-small {
    font-size: 8px;
}

.print-font-medium {
    font-size: 13px;
}

.print-font-large {
    font-size: 15px;
    font-weight: bold;
}

.print-font-deliver {
    font-size: 35px;
    font-weight: 500;
}
