/* DataTableDemo.css */


.form-demo .p-toolbar {
    flex-wrap: wrap;
}
.form-demo .p-toolbar .p-button {
    margin-bottom: 0.25rem;
}

.form-demo .table-header {
    flex-direction: column;
}

.form-demo .table-header .p-input-icon-left,
.form-demo .table-header input {
    width: 100%;
}

.datatable-responsive-demo .product-image {
    width: 90px;
    /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
}

.datatable-responsive-demo .table-header .p-input-icon-left,
.datatable-responsive-demo .table-header input {
    width: 100%;
}

.datatable-responsive-demo .p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

.datatable-responsive-demo .p-inputtext.p-component.p-column-filter {
    width: 70px;
}

.datatable-responsive-demo .p-inputtext.p-component.p-filled {
    width: 60px;
}

.datatable-responsive-demo .p-inputtext.p-component.p-filled.p-inputnumber-input {
    width: 80px;
}

.datatable-responsive-demo .p-inputtext.p-component.p-inputnumber-input {
    width: 60px;
}

.datatable-responsive-demo .p-component.p-inputwrapper.p-column-filter {
    width: 60px;
}

.datatable-responsive-demo .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
    width: 60px;
}

@media screen and (max-width: 40em) {
    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
        padding: 0.4rem;
        min-width: 30%;
        display: inline-block;
        margin: -0.4em 1em -0.4em -0.4rem;
        font-weight: bold;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }
}

/* DataTableDemo.css */

.datatable-filter-demo .p-paginator .p-paginator-current {
    margin-left: auto;
}

.datatable-filter-demo .p-progressbar {
    height: 0.5rem;
    background-color: #d8dadc;
}

.datatable-filter-demo .p-progressbar .p-progressbar-value {
    background-color: #607d8b;
}

.datatable-filter-demo .table-header {
    display: flex;
    justify-content: space-between;
}

.datatable-filter-demo .p-datepicker {
    min-width: 25rem;
}

.datatable-filter-demo .p-datepicker td {
    font-weight: 400;
}

.datatable-filter-demo .p-inputtext.p-component.p-column-filter {
    width: 100px;
}

.datatable-filter-demo .p-inputtext.p-component.p-filled.p-inputnumber-input {
    width: 100px;
}

.datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
}

.datatable-filter-demo .p-datatable.p-datatable-customers .p-paginator {
    padding: 1rem;
}

.datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
    text-align: left;
}

.datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
    cursor: auto;
}

.datatable-filter-demo .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}

.datatable-filter-demo .p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 960px) {
    .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-thead > tr > th,
    .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--layer-2);
    }

    .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;
    }

    .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
        padding: 0.4rem;
        min-width: 30%;
        display: inline-block;
        margin: -0.4rem 1rem -0.4rem -0.4rem;
        font-weight: bold;
    }

    .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-progressbar {
        margin-top: 0.5rem;
    }
}
