/* table,
th,
td {
    border: 1px solid #e3e6dd;
    border-collapse: collapse;
}

tr:nth-child(even) {
    background-color: #f3f7e9;
} */


table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #d1cccc;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }